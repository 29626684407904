#container {
 display: grid;
grid-template-areas:
 'header'
 'nav'
 'main'
 'footer';
/* width:80%; */
grid-template-columns: 1fr;
grid-template-rows: .4fr 1r 8fr 1fr;
padding:1rem;
place-items:center;
 grid-gap:1.2rem;
}


#container .header{
 display: grid;
 grid-area: header;
 /* grid-template-columns: repeat(4,1fr); */
 /* grid-template-columns: 1fr 6fr 3fr 6fr; */
 /* grid-template-columns:  2fr 3fr 2fr 3fr; */
 grid-template-columns:  3fr 6fr 1fr 3fr;
 grid-template-rows:1fr;
 place-content: center;
 grid-gap: 1rem;
 align-items: center;
 justify-items: end;
 /* background-color: #8ca0ff; */
 /* font-size: 1.5rem; */
 padding:1%;
 z-index: 2;
 width:100%;
 max-width: 1500px;
}

#container .main{
 display:grid;
 grid-area: main;
 place-content: center;
 grid-template-columns: repeat(3,1fr);
 /* max-width: 75%; */
 grid-gap: 2rem;
 align-content: center;
 padding:1%;
}

#container .banner-one{
 width: 55rem;
 aspect-ratio: 6/ 1.2;
}

#container .banner-one{
 display:flex;
 justify-content:center;
}

#container .nav2{
 display: grid;
 grid-area:nav; 
 place-content: center;
 grid-template-rows: 1fr;
 grid-template-columns: 1fr;
 place-content: center;
 grid-gap: 3rem;
 align-items: center;
 justify-items: center;
 /* z-index: -1; */
 /* background-color: #f8f8f8; */

}

#container .footer{
 grid-area:footer;
 display: grid;
 place-content: center;
}

.main > h1{
 display:grid;
 grid-gap: 1fr;
 /* border: 1px solid red; */
 padding: 1rem;
}


.submenu {
 display: grid;
 grid-gap: .9rem;
 grid-template-columns: 1fr 1fr;
 align-items: baseline;
};

.submenu-fries {
 /* width: 20rem; */
}

.checkoutModal {
 position: fixed;
 /* top: 0; */
 /* left: 0; */
 /* right:0; */
 width: 100vw;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.5);
 z-index: 1;
 display: grid;
 justify-content: center;
 align-items: center;
 place-items:center;
 min-height: calc(100% - .4rem);
}

.checkoutModal-content {

 background-color: white;
 padding: 1rem;
 border-radius: 5px;
 box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
 position: relative;
 width: 100%;
 /* height: 100%; */
 justify-content: center;
 max-width: 468px;
}

#payment-form {
 max-width: 468px;
}

.checkoutModal-close {
 position: absolute;

 top: 10px;
 right: 10px;
 font-size: 24px;
 font-weight: bold;
 cursor: pointer;
}

.red {
 color:red;
}

.text-green {
 color:green;
}

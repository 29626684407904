@media (width >= 1300px){

  #container .main{
  
   grid-gap: 3rem;
   align-content: center;
   /* max-width: 90%; */
   padding:1%;
  }
 
  .product-style {
   max-width: 400px;
   gap: 2rem;
  } 

  .login-modal, .register-modal {
    padding: 1%;
    font-size: 1rem;
   }

   .product-container {
    grid-template-columns: repeat(3,1fr);
    width:100%;
    gap:1.5rem;
    max-width:1275px;
   }

   .advert { 
    grid-column-start:1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end:3;
  }
 
 }

 

@media (width <= 950px){
  .container{
    gap: .5rem;
  }
  .header {
    width: 100%;
   }
   .login-modal, .register-modal {
    padding: 4%;
    font-size: 1rem;
   }

   
  .cart-summary {
    margin-top: 1rem;
  }

  .logo {
    height: 25px;
  }
  /* .product-container {
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 1fr .5fr 1fr 1fr;
   }

   .advert { 
    grid-column-start:1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end:3;
  } */

  .hair_serum {
    object-fit: contain;
    width: 95vw;
   }
  
}

@media (max-width:768px){
  /* .product-container {
    grid-template-columns: repeat(2,1fr);
   }
   .advert { 
    grid-column-start:1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end:3;
  } */
  

 body {
  background-color: rgb(255, 255, 255);
}
#container{
 grid-template-rows:  .1fr 0.2fr 10fr 1fr;
 padding: 0% 2% 0px 2% ;
 gap: 1.5rem;
}

#container .header {
  display: grid;
  grid-area: header;
  /* grid-template-columns: repeat(4,1fr); */
  grid-template-columns:  4fr 2fr 2fr 3fr;
  grid-template-rows: 1fr;
  place-content: center;
  grid-gap: 1rem;
  align-items: center;
  justify-items: flex-start;
  /* background-color: #8ca0ff; */
  /* font-size: 1.5rem; */
  font-size: 10px ; 
  padding: 1%;
  z-index: 2;
  /* box-shadow:none; */
  background-color:white;
  width: 100%;
  font-size: 105%;
}

.header img {
  width: 6rem;
}

hr.left-border {
  display: none;
}




.black-color {
 color: black;
 font-weight: 400;
 font-family: arial;
 font-size: 10px ;
}

 .header>h1{
 font-size: 1rem;
}

#container .main{
 display:grid;
 grid-area: main;
 grid-template-columns:repeat(2,1fr);
 place-content: center;
 grid-gap: 1rem;
 align-content: center;
 /* max-width: 90%; */
 padding:1%;
}

.main > h1{
 display:grid;
 grid-gap: 1fr;
 border: 1px solid red;
 /* padding-top: 1.5rem; */
}

[class^="btn-"] {
 font-size: 9px;
}

.navbar-brand {
 font-size: 12px;
}

.navbar-toggle {
 display: block;
}

.circle-green {
	width: 65px;
	height: 28px;
	margin-left: 16px;
}

.product-image {
 width:120px;
 height:170px;
}

.product-style {
 max-width: 280px;
 gap: 1rem;
}

.navbar-links {
 display: none;
 flex-direction: column;
 position: absolute;
 top: 100%;
 left: 0;
 width: 100%;
 padding: 20px;
 background-color: #fff;
 box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-links li {
 margin: 10px 0;
}

.navbar-links a {
 font-size: 1.2rem;
}

.navbar-links.show {
 display: flex;
}

.navbar-links.show li {
 animation: slideIn 0.5s forwards;
 animation-delay: calc(var(--i) * 0.1s);
}

@keyframes slideIn {
 from {
   transform: translateY(-50px);
   opacity: 0;
 }
 to {
   transform: translateY(0);
   opacity: 1;
 }
}

.login-modal, .register-modal {
  padding: 6%;
  font-size: 1rem;
 }

 .card-img {
  background-position: 50%;
  background-size: 100%;
  object-fit: cover;
  padding: 3%;
}


.cart-summary {
  margin-top: 1rem;
}

#container .banner-one{
  width: 35rem;
  aspect-ratio: 6/ 1.2;
 }

}

@media (width <= 767px){

  #container {
    width: 100vw;
  }
    #container .main, .product-container{
   grid-template-columns: 1fr;   
   border-left: none;  
  }
   
   .advert { 
    grid-column-start:1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end:3;
  }

  .card-container {
    /* box-shadow: 4px 6px 10px 2px rgba(0, 0, 0, 0.2); */
    /* box-shadow: 3px -3px 9px 2px rgba(0, 0, 0, 0.2); */
    box-shadow: 1px -1px 8px 2px rgba(0, 0, 0, 0.2);
    place-items:center;
    min-width: fit-content;
    padding: 3%; 
    margin: 0 1rem;
  }
 
  #container .header {
    display: grid;
    grid-area: header;
    /* grid-template-columns: repeat(4,1fr); */
    grid-template-columns: 1fr 1fr 1fr 2fr;
    grid-template-rows: 1fr;
    place-content: center;
    grid-gap: 1rem;
    align-items: center;
    justify-items: flex-start;
    /* background-color: #8ca0ff; */
    /* font-size: 1.5rem; */
    padding: 1%;
    z-index: 2;
    /* box-shadow:none; */
    background-color:white;
    width: 100%;
}
 
  .product-style {
   max-width: 285px;
   gap: .5rem;
  }

  .checkoutModal {
    /* min-width: 410px; */
    min-width: 100% !important;
    font-size: 92%;
  }

  .stripe-form {
    min-width: 390px;
    padding:0%;
  }
 
  .header img{
   width: 5rem;
    height: auto;
  }

 [value="Register"]{
    /* display: none; */
    font-size: 135%;
    color:grey;
    background-color: white;
    padding:2%;
  }

  .btn-green-login-mobile {
    padding:10%;
    padding-left: 25%;
    padding-right: 25%;
    font-size: 135%;    
  }

  .btn-blue-cart {
    padding:7%;
    padding-left: 25%;
    padding-right: 25%;
    border-radius: 1rem;
  }
 
  .header {
   width: 100%;
  }
 
  .login-modal, .register-modal {
   padding: 8%;
   /* font-size: initial; */
  }

  .card-img {
    background-position: 50%;
    background-size: 100%;
    object-fit: cover;
    padding: 3%;
}
 
hr.left-border {
  display: none;
}

.btn-blue-add {
  display: none;
}

.cart-summary {
  margin-top: 1rem;
}

 .Toastify__toast {
  width: 65%;
 }

 .add-to-cart {
  grid-template-columns: .6fr 1fr .4fr;
  /* justify-content: center; */
  justify-items:center;
 }

 .card-text, .card-body {
  width: 80%;
 }

 #container .banner-one{
  width: 25rem;
  aspect-ratio: 6/ 1.2;
 }

 }

 
 @media (width <= 550px) {

  #tooltip-hover:hover .tooltiptext {
   width: 95vw;
   display: flex;
   visibility: visible;
   opacity: 1;
 }
 
 }
 
@media (width <= 400px) {
  .login-modal, .register-modal {
    padding: 8%;
   }

   .card-img {
    background-position: 50%;
    background-size: 100%;
    object-fit: cover;
    padding: 3%;
}
}





/* sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1400px */

.header img{
 width: 13rem;
  height: auto;
}

.cart-container {
  position: relative;
}

.cart-summary{
  display:grid;
  grid-gap: .8rem;
  grid-template-columns: 1fr;
  grid-template-rows:auto;
justify-items: end;
}

.cart-summary {
  position: absolute;
  top: 38px;
  left: 0;
  /* width: 15rem; */
  background-color: #f2f2f2;
  padding: 5%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.cart-content {
  margin-top: 38px;
}
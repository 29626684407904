
.product-container{
 grid-area: main;
 display: grid;
 grid-template-columns: repeat(2,1fr);
 /* grid-template-rows: 1fr .5fr 1fr; */
 grid-auto-rows: .6fr;
 width:100%;
 gap:1rem;
 place-items:center;
/* align-items:baseline; */
 max-width:900px;
 border-left: 1px solid #d0cccc;
}


.advert {
  display:flex;
  justify-content: space-around;
  grid-column-start:1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end:3;
}

.card-container {
/* grid-area: card-container; */
 display:grid;
 grid-template-rows: .3fr .8fr .2fr .5fr;
 /* place-items: center; */
 /* align-items: center; */
 /* box-shadow: 3px 5px 8px 2px rgba(0, 0, 0, 0.2); */
 max-width:385px;
 grid-gap: 1.5rem;
 min-height: 550px;
 justify-items: center;
}

.card-container img {
  place-items: center;
}

.card-img  {
  object-fit: contain;
  /* width: 100%; */
  padding: 3%;
  background-size: 100%;
  background-position: center;
}
.add-to-cart {
 display: grid;
 grid-template-rows: 1fr;
 grid-template-columns: .6fr 1fr .4fr .8fr;
 grid-gap: .3rem;
 /* place-content:center; */
 justify-items:center;
}

.meal-selection {
 display: grid;
 grid-gap: 1rem;
 grid-template-columns: 2fr .5fr;
 grid-template-rows: 1fr ;
 /* width: 80%; */
}

.drink-selection{
 display:grid;
 grid-gap:.8rem;
 /* width: 8rem; */
 }

 .card-text {
  width: 74%;
 }

 /* .submenu {
  display: grid;
  grid-gap: .9rem;
  grid-template-columns: 3fr 1fr;
  align-items: baseline;
   width: 20rem;
 }; */

 .card-body {
  width: 85%;
 }

 .card-title {
  text-align: center;
 }

 .tooltip {
  position: relative;
  display: flex;
  gap: 0.5rem;
  align-items: center;
 }

.tooltiptext {
  visibility: hidden;
  display: none;
  /* visibility: visible;  */
  /* width: 120px; */
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

#tooltip-hover:hover .tooltiptext {
  width: 28vw;
  display: flex;
  visibility: visible;
  opacity: 1;
}